<template>
  <div class="topbg-wrapper">
    <div class="top-bg">
      <div class="top-header ">
        <div class="header-between flex-item"></div>
        <div class="header-center">{{ $t("跨鏈橋") }}</div>
        <div class="header-between flex-item"></div>
      </div>
    </div>
    <div class="from-box">
      <!-- from -->
      <el-dropdown trigger="click">
        <div class="from-top flex-middle">
          <img
            v-if="chainValue1.cName === 'bsc'"
            src="~@/assets/image/pc/BNB.png"
            alt=""
          />
          <img
            v-else-if="chainValue1.cName === 'eth'"
            src="~@/assets/image/pc/erc20.png"
            alt=""
          />
          <img
            v-else-if="chainValue1.cName === 'trx'"
            src="~@/assets/image/pc/TRX.png"
            alt=""
          />
          <img v-else src="~@/assets/image/pc/back-logo.png" alt="" />
          <span class="text-uppercase">{{ chainValue1.cName }}</span>
          <img src="~@/assets/image/pc/a_b.png" alt="" class="arrow" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in chains1"
              :key="item.id"
              class="flex-middle"
              @click="changeChainValue1(item)"
            >
              <img
                v-if="item.cName === 'bsc'"
                src="~@/assets/image/pc/BNB.png"
                alt=""
                class="logo"
              />
              <img
                v-else-if="item.cName === 'eth'"
                src="~@/assets/image/pc/erc20.png"
                alt=""
                class="logo"
              />
              <img
                v-else-if="item.cName === 'trx'"
                src="~@/assets/image/pc/TRX.png"
                alt=""
                class="logo"
              />
              <img
                v-else
                src="~@/assets/image/pc/back-logo.png"
                alt=""
                class="logo"
              />
              <span class="text-uppercase">{{ item.cName }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- from amount -->
      <el-input
        type="number"
        :placeholder="$t('輸入兌換金額')"
        v-model="amount1"
        class="input-with-select"
        @input="changeAmount1()"
      >
        <template #append>
          <el-dropdown trigger="click">
            <div class="flex-middle token-dropdown">
              <span class="text-uppercase">{{ tokenValue1.name }}</span>
              <img src="~@/assets/image/pc/a_b.png" alt="" class="arrow" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in chainValue1.tokens"
                  :key="item.name"
                  @click="changeTokenValue1(item)"
                >
                  <span class="text-uppercase">{{ item.name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-input>
      <div class="form-tips">
        {{ $t("可用") }}
        {{
          balance
            ? BigNumber(balance)
                .dividedBy(Math.pow(10, decimals))
                .toFixed(6, 1)
            : 0
        }}
      </div>
      <div class="change-box mt">
        <img
          class="change-img"
          @click="exchangeChain"
          src="~@/assets/image/pc/change.png"
          alt=""
        />
      </div>

      <!-- to -->
      <el-dropdown trigger="click">
        <div class="from-top flex-middle">
          <img
            v-if="chainValue2.cName === 'bsc'"
            src="~@/assets/image/pc/BNB.png"
            alt=""
          />
          <img
            v-else-if="chainValue2.cName === 'eth'"
            src="~@/assets/image/pc/erc20.png"
            alt=""
          />
          <img
            v-else-if="chainValue2.cName === 'trx'"
            src="~@/assets/image/pc/TRX.png"
            alt=""
          />
          <img v-else src="~@/assets/image/pc/back-logo.png" alt="" />
          <span class="text-uppercase">{{ chainValue2.cName }}</span>
          <img src="~@/assets/image/pc/a_b.png" alt="" class="arrow" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              v-for="item in chains2"
              :key="item.id"
              class="flex-middle"
              @click="changeChainValue2(item)"
            >
              <img
                v-if="item.cName === 'bsc'"
                src="~@/assets/image/pc/BNB.png"
                alt=""
                class="logo"
              />
              <img
                v-else-if="item.cName === 'eth'"
                src="~@/assets/image/pc/erc20.png"
                alt=""
                class="logo"
              />
              <img
                v-else-if="item.cName === 'trx'"
                src="~@/assets/image/pc/TRX.png"
                alt=""
                class="logo"
              />
              <img
                v-else
                src="~@/assets/image/pc/back-logo.png"
                alt=""
                class="logo"
              />
              <span class="text-uppercase">{{ item.cName }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <!-- to amount -->
      <el-input
        type="number"
        :placeholder="$t('輸入兌換金額')"
        v-model="amount2"
        class="input-with-select"
        @input="changeAmount2()"
      >
        <template #append>
          <el-dropdown trigger="click">
            <div class="flex-middle token-dropdown">
              <span class="text-uppercase">{{ tokenValue2.name }}</span>
              <img src="~@/assets/image/pc/a_b.png" alt="" class="arrow" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in chainValue2.tokens"
                  :key="item.name"
                  @click="changeTokenValue2(item)"
                >
                  <span class="text-uppercase">{{ item.name }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-input>

      <div class="form-tips">
        {{ $t("兌換價格") }} 1.000000
        <span class="text-uppercase">{{ tokenValue1.name }}</span> ≈
        {{ price || "--" }}
        <span class="text-uppercase">{{ tokenValue2.name }}</span>
      </div>
      <div class="form-tips">
        {{ $t("手續費") }}
        <span v-if="priceInfo && chainValue1.cName === 'bsc'">{{
          priceInfo.bscfee
        }}</span>
        <span v-else-if="priceInfo && chainValue1.cName === 'eth'">{{
          priceInfo.ethfee
        }}</span>
        <span v-else-if="priceInfo && chainValue1.cName === 'trx'">{{
          priceInfo.tronfee
        }}</span>
        <span v-else-if="priceInfo && chainValue1.cName === 'back'">{{
          priceInfo.backfee
        }}</span>
        <span v-else>--</span>
        %
      </div>

      <div class="address-input mt">
        <el-input
          :placeholder="$t('請輸入接收地址')"
          v-model="rAddress"
          class="input-with-select"
        >
        </el-input>
      </div>
      <div class="form-btn" v-if="chainValue1.cName === 'trx'">
        <el-button
          type="primary"
          block
          round
          v-if="!TronAccounts"
          @click="initTronWallet(chainValue1.chainId)"
          >{{ $t("鏈接錢包") }}</el-button
        >
        <el-button
          type="primary"
          block
          round
          v-else-if="
            tokenValue1.isToken &&
              (!Number(allowance) || BigNumber(allowance).lt(amount1))
          "
          @click="approveTron"
          :disabled="loading"
          :loading="loading"
          >{{ $t("授權") }}</el-button
        >
        <el-button
          type="primary"
          block
          round
          v-else
          @click="toCheck"
          :disabled="loading"
          :loading="loading"
          >{{ $t("確認") }}</el-button
        >
      </div>
      <div class="form-btn" v-else>
        <el-button
          type="primary"
          block
          round
          v-if="!address"
          @click="initWallet(chainValue1.chainId)"
          >{{ $t("鏈接錢包") }}</el-button
        >
        <el-button
          type="primary"
          block
          round
          v-else-if="
            tokenValue1.isToken &&
              (!Number(allowance) || BigNumber(allowance).lt(amount1))
          "
          @click="approve"
          :disabled="loading"
          :loading="loading"
          >{{ $t("授權") }}</el-button
        >
        <el-button
          type="primary"
          block
          round
          v-else
          @click="toCheck"
          :disabled="loading"
          :loading="loading"
          >{{ $t("確認") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, watch } from "vue";
import { getPriceApi } from "@/api";
import { useStore } from "vuex";
import WalletContract from "@/components/WalletContract";
import { backChainContract, otherChainContract } from "@/utils/config";
import BigNumber from "bignumber.js";
import web3Data from "@/utils/initWeb3";
import tokenAbi from "@/utils/abi";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import { MaxUint256 } from "@ethersproject/constants";
import cloneDeep from "lodash/cloneDeep";
import Web3 from "web3";
export default {
  components: {
    WalletContract
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const data = reactive({
      BigNumber: BigNumber,
      web3: computed(() => store.state.provider),
      address: computed(() => store.state.accounts[0]),
      TronAccounts: computed(() => store.state.TronAccounts),
      TronWeb: computed(() => store.state.TronProvider),
      netId: computed(() => store.state.netId),
      chains1: cloneDeep(otherChainContract),
      chains2: cloneDeep(backChainContract),
      chainValue1: {}, // 選中的鏈 from
      chainValue2: {}, // 選中的鏈 to
      tokenValue1: {}, // 選中的幣 from
      tokenValue2: {}, // 選中的幣 to
      amount1: "",
      amount2: "",
      rAddress: "",
      price: "1.000000",
      transferContract: null,
      tokenContract: null,
      allowance: 0,
      balance: 0,
      decimals: 0,
      transaction: {},
      priceInfo: null,
      isTrx: computed(() => {
        return data.chainValue1.cName === "trx";
      }),
      isToken: computed(() => {
        return data.tokenValue1.isToken;
      }),
      crossAbi: {
        eth: require("@/utils/crosschain/eth"),
        bsc: require("@/utils/crosschain/bsc"),
        trx: require("@/utils/crosschain/trx"),
        back: require("@/utils/crosschain/back")
      },
      loading: false,
      initData: () => {
        // 預設from鏈
        data.chainValue1 = data.chains1[0];
        // 預設from幣
        data.tokenValue1 = data.chainValue1.tokens[0];
        // 預設to鏈
        data.chainValue2 = data.chains2[0];
        // 篩選to鏈可選擇的幣
        let tokens2 = data.chainValue2.tokens;
        data.chainValue2.tokens = tokens2.filter(item => {
          return (
            item.cName.toLowerCase() === data.chainValue1.cName.toLowerCase() ||
            item.cName.toLowerCase() === "back"
          );
        });
        // 預設to幣
        data.tokenValue2 = data.chainValue2.tokens[0];
      },
      initContract: () => {
        data.transferContract = new data.web3.eth.Contract(
          data.crossAbi[data.chainValue1.cName],
          data.chainValue1.transferAddress
        );
        data.initTokenContract();
      },
      initContractTron: () => {
        data.transferContract = data.TronWeb.contract(
          data.crossAbi[data.chainValue1.cName],
          data.chainValue1.transferAddress
        );
        data.initTokenContractTron();
      },
      initTokenContractTron: () => {
        if (data.isToken) {
          data.tokenContract = data.TronWeb.contract(
            tokenAbi,
            data.tokenValue1.address
          );
          data.getAllowanceTron();
        }
        data.getBalanceTron();
      },
      getAllowanceTron: async () => {
        let allowance = await data.tokenContract.methods
          .allowance(data.TronAccounts, data.chainValue1.transferAddress)
          .call();
        data.allowance = allowance.toString();
      },
      getBalanceTron: async () => {
        if (data.isToken) {
          data.decimals = await data.tokenContract.methods.decimals().call();
          let balance = await data.tokenContract.methods
            .balanceOf(data.TronAccounts)
            .call();
          data.balance = balance.toString();
        } else {
          data.decimals = 6;
          data.TronWeb.trx.getBalance(data.TronAccounts).then(result => {
            data.balance = result;
          });
        }
      },
      initTokenContract: () => {
        if (data.isToken) {
          data.tokenContract = new data.web3.eth.Contract(
            tokenAbi,
            data.tokenValue1.address
          );
          data.getAllowance();
        }
        data.getBalance();
      },
      getBalance: async () => {
        if (data.isToken) {
          data.decimals = await data.tokenContract.methods.decimals().call();
          data.balance = await data.tokenContract.methods
            .balanceOf(data.address)
            .call();
        } else {
          data.decimals = 18;
          new data.web3.eth.getBalance(data.address).then(balance => {
            data.balance = balance;
          });
        }
      },
      getAllowance: async () => {
        data.allowance = await data.tokenContract.methods
          .allowance(data.address, data.chainValue1.transferAddress)
          .call();
      },
      approve: () => {
        data.loading = true;
        data.tokenContract.methods
          .approve(data.chainValue1.transferAddress, MaxUint256)
          .send({ from: data.address })
          .then(result => {
            data.loading = false;
            data.getAllowance();
          })
          .catch(() => {
            data.loading = false;
          });
      },
      approveTron: async item => {
        data.loading = true;
        data.tokenContract.methods
          .approve(data.chainValue1.transferAddress, MaxUint256)
          .send({ from: data.TronAccounts })
          .then(result => {
            data.checkTronResult(result, "approve");
          })
          .catch(() => {
            data.loading = false;
          });
      },
      changeTokenValue1: item => {
        data.tokenValue1 = item;
        if (!data.isTrx && data.address) {
          data.initTokenContract();
        }
        if (data.isTrx && data.TronAccounts) {
          data.initTokenContractTron();
        }
      },
      changeTokenValue2: item => {
        data.tokenValue2 = item;
      },
      changeChainValue1: item => {
        if (item.cName === data.chainValue1.cName) {
          return;
        }
        data.chainValue1 = item;
        data.tokenValue1 = item.tokens[0];
        if (data.chainValue1.cName !== "back") {
          store.commit("updateAccount", []);
          store.commit("updateTronAccount", "");
          // 預設to鏈
          data.chainValue2 = cloneDeep(backChainContract)[0];
          // 篩選to鏈可選擇的幣
          let tokens = data.chainValue2.tokens;
          data.chainValue2.tokens = tokens.filter(item => {
            return (
              item.cName.toLowerCase() ===
                data.chainValue1.cName.toLowerCase() ||
              item.cName.toLowerCase() === "back"
            );
          });
          // 預設to幣
          data.tokenValue2 = data.chainValue2.tokens[0];
        }
      },
      changeChainValue2: item => {
        if (item.cName === data.chainValue2.cName) {
          return;
        }
        data.chainValue2 = item;
        data.tokenValue2 = item.tokens[0];
        if (data.chainValue2.cName !== "back") {
          // 預設to鏈
          data.chainValue1 = cloneDeep(backChainContract)[0];
          // 篩選to鏈可選擇的幣
          let tokens = data.chainValue1.tokens;
          data.chainValue1.tokens = tokens.filter(item => {
            return (
              item.cName.toLowerCase() ===
                data.chainValue2.cName.toLowerCase() ||
              item.cName.toLowerCase() === "back"
            );
          });
          // 預設to幣
          data.tokenValue1 = data.chainValue1.tokens[0];
          data.initTokenContract();
        }
      },
      initWallet: chainId => {
        web3Data.initWeb3(chainId);
      },
      initTronWallet: () => {
        web3Data.initTronWeb();
      },
      getPrice: () => {
        getPriceApi().then(res => {
          data.priceInfo = res.data;
        });
      },
      changeAmount1: () => {
        if (data.amount1 && new BigNumber(data.amount1).gt(0)) {
          data.amount2 = new BigNumber(data.amount1)
            .multipliedBy(data.price)
            .toFixed(6, 1);
        } else {
          data.amount2 = "";
        }
      },
      changeAmount2: () => {
        if (data.amount2 && new BigNumber(data.amount2).gt(0)) {
          data.amount1 = new BigNumber(data.amount2)
            .multipliedBy(1 / data.price)
            .toFixed(6, 1);
        } else {
          data.amount1 = "";
        }
      },
      exchangeChain: () => {
        store.commit("updateAccount", []);
        store.commit("updateTronAccount", "");
        let chains1 = data.chains2;
        let chainValue1 = data.chainValue2;
        let tokenValue1 = data.tokenValue2;

        data.chains2 = data.chains1;
        data.chainValue2 = data.chainValue1;
        data.tokenValue2 = data.tokenValue1;

        data.chains1 = chains1;
        data.chainValue1 = chainValue1;
        data.tokenValue1 = tokenValue1;
      },
      toCheck: async () => {
        if (data.netId != data.chainValue1.chainId) {
          ElMessage.error(t("請切換到正確的網路"));
          return;
        }
        let decimals = 0;
        if (data.isToken) {
          decimals = await data.tokenContract.methods.decimals().call();
        } else {
          if (data.isTrx) {
            decimals = 6;
          } else {
            decimals = 18;
          }
        }
        let amount = new BigNumber(data.amount1)
          .multipliedBy(Math.pow(10, decimals))
          .toFixed();
        let coinAddress = data.tokenValue1.address;
        let cName = data.chainValue1.cName;
        let chainId = data.chainValue2.id;
        let coinName = data.tokenValue2.name;
        let otherAddress = data.rAddress;
        if (!Number(amount)) {
          ElMessage.error(t("請輸入兌換金額"));
          return;
        }
        if (new BigNumber(data.balance).lt(amount)) {
          ElMessage.error(t("餘額不足"));
          return;
        }
        if (!otherAddress) {
          ElMessage.error(t("請輸入接收地址"));
          return;
        }
        if (
          data.chainValue2.cName === "trx" &&
          !window.tronWeb.isAddress(otherAddress)
        ) {
          ElMessage.error(t("地址錯誤"));
          return;
        }
        if (
          data.chainValue2.cName !== "trx" &&
          !Web3.utils.isAddress(otherAddress)
        ) {
          ElMessage.error(t("地址錯誤"));
          return;
        }
        if (data.isTrx) {
          data.submitTron(
            amount,
            coinAddress,
            chainId,
            coinName,
            otherAddress,
            cName
          );
        } else {
          data.submit(
            amount,
            coinAddress,
            chainId,
            coinName,
            otherAddress,
            cName
          );
        }
      },
      submitTron: (
        amount,
        coinAddress,
        chainId,
        coinName,
        otherAddress,
        cName
      ) => {
        let sendObj = {};
        if (data.isToken) {
          sendObj = { from: data.TronAccounts };
        } else {
          sendObj = { from: data.TronAccounts, callValue: amount };
        }
        data.loading = true;

        data.transferContract.methods
          .crossChainTron(amount, coinAddress, chainId, coinName, otherAddress)
          .send({ ...sendObj })
          .then(output => {
            data.checkTronResult(output);
          })
          .catch(err => {
            data.loading = false;
          });
      },
      checkTronResult: (id, type) => {
        window.clearInterval(data.transaction[id]);
        data.transaction[id] = setInterval(() => {
          data.TronWeb.trx
            .getConfirmedTransaction(id)
            .then(res => {
              if (res.ret[0].contractRet === "SUCCESS") {
                window.clearInterval(data.transaction[id]);
                data.loading = false;
                if (type === "approve") {
                  data.getAllowanceTron();
                } else {
                  ElMessage.success(t("交易成功"));
                  data.amount1 = "";
                  data.amount2 = "";
                  data.getBalanceTron();
                }
              } else {
                window.clearInterval(data.transaction[id]);
                data.loading = false;
                ElMessage.error(t("交易失敗"));
              }
            })
            .catch(() => {
              //  Toast('交易失敗' + JSON.stringify(err))
            });
        }, 2000);
      },
      submit: (amount, coinAddress, chainId, coinName, otherAddress, cName) => {
        let sendObj = {};
        if (data.isToken) {
          sendObj = { from: data.address };
        } else {
          sendObj = { from: data.address, value: amount };
        }
        data.loading = true;
        data.transferContract.methods[
          `crossChain${cName.substring(0, 1).toUpperCase() +
            cName.substring(1)}`
        ](amount, coinAddress, chainId, coinName, otherAddress)
          .estimateGas({ ...sendObj })
          .then(gas => {
            data.transferContract.methods[
              `crossChain${cName.substring(0, 1).toUpperCase() +
                cName.substring(1)}`
            ](amount, coinAddress, chainId, coinName, otherAddress)
              .send({ ...sendObj, gas: gas })
              .then(() => {
                ElMessage.success(t("交易成功"));
                data.loading = false;
                data.amount1 = "";
                data.amount2 = "";
                data.getBalance();
              })
              .catch(() => {
                data.loading = false;
                ElMessage.error(t("交易失敗"));
              });
          })
          .catch(err => {
            data.loading = false;
          });
      }
    });
    watch(
      () => data.TronAccounts,
      () => {
        if (data.TronAccounts) {
          data.initContractTron();
        }
      }
    );
    watch(
      () => data.address,
      () => {
        if (data.address) {
          data.initContract();
        }
      }
    );
    data.initData();
    data.getPrice();
    watch(
      () => data.tokenValue1,
      () => {
        data.balance = 0;
        data.allowance = 0;
      }
    );
    watch(
      () => [data.tokenValue1, data.tokenValue2],
      () => {
        data.amount1 = "";
        data.amount2 = "";
        data.price = "";
        if (data.tokenValue1.name && data.tokenValue2.name) {
          let name1 = data.tokenValue1.name
            .toLowerCase()
            .replace("usdq", "usdt");
          let name2 = data.tokenValue2.name
            .toLowerCase()
            .replace("usdq", "usdt");
          if (name1 === name2) {
            data.price = new BigNumber(1).toFixed(6);
          } else {
            if (name1 === "bnb") {
              data.price = data.priceInfo ? data.priceInfo.bnbprice : "";
            }
            if (name1 === "eth") {
              data.price = data.priceInfo ? data.priceInfo.ethprice : "";
            }
            if (name1 === "trx") {
              data.price = data.priceInfo ? data.priceInfo.trxprice : "";
            }
            if (name1 === "usdt") {
              if (name2 === "bnb") {
                data.price = data.priceInfo
                  ? new BigNumber(1)
                      .dividedBy(data.priceInfo.bnbprice)
                      .toFixed(6, 1)
                  : "";
              }
              if (name2 === "eth") {
                data.price = data.priceInfo
                  ? new BigNumber(1)
                      .dividedBy(data.priceInfo.ethprice)
                      .toFixed(6, 1)
                  : "";
              }
              if (name2 === "trx") {
                data.price = data.priceInfo
                  ? new BigNumber(1)
                      .dividedBy(data.priceInfo.trxprice)
                      .toFixed(6, 1)
                  : "";
              }
            }
          }
        }
      }
    );
    const refData = toRefs(data);
    return {
      ...refData
    };
  }
};
</script>
<style lang="scss" scoped>
.from-box {
  margin-bottom: 50px;
}
.logo {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}

@media screen and (max-width: 480px) {
  /deep/ .el-input__inner:last-child {
    border: 1px solid #dcdfe6;
  }
  /deep/.el-select .el-input .el-select__caret {
    cursor: none;
  }
  /deep/ .is-focus {
    cursor: none !important;
  }
}
</style>
